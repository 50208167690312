import consumer from "./consumer"

consumer.subscriptions.create("AdminNotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log(data);
    $.ajax({
      url: '/admin/jobs/notifications',
      type: 'GET',
      dataType: "script",
      success: function (data1) {
       
       var a = $("#notifi_count_sidebar")
       var a1 = $("#notifi_count_sidebars")
       if(a){
        console.log("success"+ data.count);
        a[0].innerHTML = data.count
        // debugger
      }
      if(a1){
        console.log("sus"+ data.count);
        a1[0].innerHTML = data.count
        // debugger
      }
      },
      error: function (data) {
        console.log("failed")
      },
    });
    if(window.location.pathname == "/admin/notifications"){
      var r_partial=null
      if(data && data.sms && data.sms=="render"){
        r_partial="all"
      }
      $.ajax({
        url: '/admin/notifications',
        type: 'GET',
        data: {
          page: 1,
          limit: data.id,
          render_prtial: r_partial
        },
        dataType: "script",
        success: function (data) {
          var no_job = $("#no_notifications").html()
          if (no_job != null) {
            $('#fetch-more').hide()
          }
        },
        error: function (data) {
          $('#fetch-more').hide()
          $("#show").append("<h4 class='text-center'>No More Notifications Are Available   from js</h4>")
        },
      });
    }
    
    // Called when there's incoming data on the websocket for this channel
  }
});
